var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"documentsDataForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-row',{staticClass:"p-2 justify-content-center"},[_c('b-col',{staticStyle:{"text-align":"center"},attrs:{"lg":"12"}},[_c('h5',{staticClass:"mt-1 mb-3"},[_vm._v(" Carga de Documentación ")])]),_c('b-col',{staticStyle:{"text-align":"start","margin-bottom":"32px"},attrs:{"lg":"12"}},[_c('span',{staticClass:"mt-1 mb-5"},[_vm._v(" Documentación obligatoria ")])]),_c('b-col',{staticClass:"mb-1",attrs:{"sm":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Copia de DNI de los representantes legales y accionistas","label-for":"dniFile"}},[_c('validation-provider',{attrs:{"name":"Copia de DNI","vid":"dniFile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"dniFile","state":errors.length > 0 ? false:null,"name":"dniFile","accept":"application/pdf","placeholder":"Ningún archivo elegido","browse-text":"Cargar archivo","disabled":!_vm.canEdit},on:{"change":_vm.onDNIFileChange},model:{value:(_vm.dniFile),callback:function ($$v) {_vm.dniFile=$$v},expression:"dniFile"}}),(_vm.dniFileURL!=='')?_c('b-row',{staticClass:"my-1 px-3"},[_c('span',{staticClass:"text-center"},[_vm._v(" (Archivo registrado anteriormente) ")]),_c('a',{staticClass:"text-info",attrs:{"target":"_blank","href":_vm.dniFileURL}},[_vm._v(" "+_vm._s(_vm.dniFileURL)+" ")])]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"sm":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Reporte tributario de terceros en PDF","label-for":"serviceFile"}},[_c('validation-provider',{attrs:{"name":"Reporte tributario de terceros en PDF","vid":"serviceFile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"serviceFile","state":errors.length > 0 ? false:null,"name":"serviceFile","accept":"application/pdf","placeholder":"Ningún archivo elegido","browse-text":"Cargar archivo","disabled":!_vm.canEdit},on:{"change":_vm.onServiceFileChange},model:{value:(_vm.serviceFile),callback:function ($$v) {_vm.serviceFile=$$v},expression:"serviceFile"}}),(_vm.serviceFileURL!=='')?_c('b-row',{staticClass:"my-1 px-3"},[_c('span',{staticClass:"text-center"},[_vm._v(" (Archivo registrado anteriormente) ")]),_c('a',{staticClass:"text-info",attrs:{"target":"_blank","href":_vm.serviceFileURL}},[_vm._v(" "+_vm._s(_vm.serviceFileURL)+" ")])]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"sm":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Ficha RUC","label-for":"rucFile"}},[_c('validation-provider',{attrs:{"name":"Ficha RUC","vid":"rucFile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"rucFile","state":errors.length > 0 ? false:null,"name":"rucFile","accept":"application/pdf","placeholder":"Ningún archivo elegido","browse-text":"Cargar archivo","disabled":!_vm.canEdit},on:{"change":_vm.onRucFileChange},model:{value:(_vm.rucFile),callback:function ($$v) {_vm.rucFile=$$v},expression:"rucFile"}}),(_vm.rucFileURL!=='')?_c('b-row',{staticClass:"my-1 px-3"},[_c('span',{staticClass:"text-center"},[_vm._v(" (Archivo registrado anteriormente) ")]),_c('a',{staticClass:"text-info",attrs:{"target":"_blank","href":_vm.rucFileURL}},[_vm._v(" "+_vm._s(_vm.rucFileURL)+" ")])]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"sm":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Vigencia de poderes (Original)","label-for":"sunarpFile"}},[_c('validation-provider',{attrs:{"name":"Vigencia de poderes","vid":"sunarpFile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"sunarpFile","state":errors.length > 0 ? false:null,"name":"sunarpFile","accept":"application/pdf","placeholder":"Ningún archivo elegido","browse-text":"Cargar archivo","disabled":!_vm.canEdit},on:{"change":_vm.onSunarpFileChange},model:{value:(_vm.sunarpFile),callback:function ($$v) {_vm.sunarpFile=$$v},expression:"sunarpFile"}}),(_vm.sunarpFileURL!=='')?_c('b-row',{staticClass:"my-1 px-3"},[_c('span',{staticClass:"text-center"},[_vm._v(" (Archivo registrado anteriormente) ")]),_c('a',{staticClass:"text-info",attrs:{"target":"_blank","href":_vm.sunarpFileURL}},[_vm._v(" "+_vm._s(_vm.sunarpFileURL)+" ")])]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"sm":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Reporte tributario de terceros en XML","label-for":"taxReportFile"}},[_c('validation-provider',{attrs:{"name":"Reporte tributario de terceros en XML","vid":"taxReportFile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"taxReportFile","state":errors.length > 0 ? false:null,"name":"taxReportFile","accept":".xml","placeholder":"Ningún archivo elegido","browse-text":"Cargar archivo","disabled":!_vm.canEdit},on:{"change":_vm.onTaxReportFileChange},model:{value:(_vm.taxReportFile),callback:function ($$v) {_vm.taxReportFile=$$v},expression:"taxReportFile"}}),(_vm.taxReportFileURL!=='')?_c('b-row',{staticClass:"my-1 px-3"},[_c('span',{staticClass:"text-center"},[_vm._v(" (Archivo registrado anteriormente) ")]),_c('a',{staticClass:"text-info",attrs:{"target":"_blank","href":_vm.taxReportFileURL}},[_vm._v(" "+_vm._s(_vm.taxReportFileURL)+" ")])]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-form-group',{staticClass:"text-center mt-1",attrs:{"label":"Tipo de régimen","label-for":"regimeType"}},[_c('b-form-radio-group',{staticClass:"text-center mt-1",attrs:{"id":"regimeType","options":_vm.optionsRegimeType,"value-field":"value","text-field":"text","name":"regimeType","disabled":!_vm.canEdit},model:{value:(_vm.regimeType),callback:function ($$v) {_vm.regimeType=$$v},expression:"regimeType"}})],1)],1)],1),(_vm.regimeType == '0')?_c('b-col',{staticClass:"mb-1 mt-3",attrs:{"sm":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Declaración jurada de último año (RG)","label-for":"affidavitFile"}},[_c('validation-provider',{attrs:{"name":"Declaración jurada de último año","vid":"affidavitFile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"affidavitFile","state":errors.length > 0 ? false:null,"name":"affidavitFile","accept":"application/pdf","placeholder":"Ningún archivo elegido","browse-text":"Cargar archivo","disabled":!_vm.canEdit},on:{"change":_vm.onAffidavitFileChange},model:{value:(_vm.affidavitFile),callback:function ($$v) {_vm.affidavitFile=$$v},expression:"affidavitFile"}}),(_vm.affidavitFileURL!=='')?_c('b-row',{staticClass:"my-1 px-3"},[_c('span',{staticClass:"text-center"},[_vm._v(" (Archivo registrado anteriormente) ")]),_c('a',{staticClass:"text-info",attrs:{"target":"_blank","href":_vm.affidavitFileURL}},[_vm._v(" "+_vm._s(_vm.affidavitFileURL)+" ")])]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2314665957)})],1)],1):(_vm.regimeType == '1')?_c('b-col',{staticClass:"mb-1 mt-3",attrs:{"sm":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Declaración y últimos 3 pagos de IGV/Renta (RE)","label-for":"declarationFile"}},[_c('validation-provider',{attrs:{"name":"Declaración y últimos 3 pagos de IGV/Renta","vid":"declarationFile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"declarationFile","state":errors.length > 0 ? false:null,"name":"declarationFile","accept":"application/pdf","placeholder":"Ningún archivo elegido","browse-text":"Cargar archivo","disabled":!_vm.canEdit},on:{"change":_vm.onDeclarationFileChange},model:{value:(_vm.declarationFile),callback:function ($$v) {_vm.declarationFile=$$v},expression:"declarationFile"}}),(_vm.declarationFileURL!=='')?_c('b-row',{staticClass:"my-1 px-3"},[_c('span',{staticClass:"text-center"},[_vm._v(" (Archivo registrado anteriormente) ")]),_c('a',{staticClass:"text-info",attrs:{"target":"_blank","href":_vm.declarationFileURL}},[_vm._v(" "+_vm._s(_vm.declarationFileURL)+" ")])]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1):_vm._e()],1),_c('b-row',{staticClass:"justify-content-center mt-4 mb-4"},[_c('b-col',{attrs:{"sm":"6","md":"3"}},[_c('b-button',{staticClass:"my-1",attrs:{"variant":"secondary","to":{name:'MainBankAccounts-legal-data'},"block":""}},[_vm._v(" Volver ")])],1),_c('b-col',{attrs:{"sm":"6","md":"3"}},[_c('b-button',{staticClass:"my-1",attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.canEdit ? "Guardar y avanzar" : "Siguiente")+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }