<template>
  <b-card>
    <b-col
      cols="12"
    >
      <!-- form -->
      <validation-observer ref="documentsDataForm">
        <b-form
          @submit.prevent="validationForm"
        >
          <b-row
            class="p-2 justify-content-center"
          >
            <b-col
              style="text-align: center"
              lg="12"
            >
              <h5 class="mt-1 mb-3">
                Carga de Documentación
              </h5>
            </b-col>
            <b-col
              style="text-align: start; margin-bottom: 32px"
              lg="12"
            >
              <span class="mt-1 mb-5">
                Documentación obligatoria
              </span>
            </b-col>
            <b-col
              sm="12"
              md="8"
              class="mb-1"
            >
              <b-form-group
                label="Copia de DNI de los representantes legales y accionistas"
                label-for="dniFile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Copia de DNI"
                  vid="dniFile"
                >
                  <b-form-file
                    id="dniFile"
                    v-model="dniFile"
                    :state="errors.length > 0 ? false:null"
                    name="dniFile"
                    accept="application/pdf"
                    placeholder="Ningún archivo elegido"
                    browse-text="Cargar archivo"
                    :disabled="!canEdit"
                    @change="onDNIFileChange"
                  />
                  <b-row
                    v-if="dniFileURL!==''"
                    class="my-1 px-3"
                  >
                    <span
                      class="text-center"
                    >
                      (Archivo registrado anteriormente)
                    </span>
                    <a
                      class="text-info"
                      target="_blank"
                      :href="dniFileURL"
                    >
                      {{ dniFileURL }}
                    </a>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="8"
              class="mb-1"
            >
              <b-form-group
                label="Reporte tributario de terceros en PDF"
                label-for="serviceFile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Reporte tributario de terceros en PDF"
                  vid="serviceFile"
                >
                  <b-form-file
                    id="serviceFile"
                    v-model="serviceFile"
                    :state="errors.length > 0 ? false:null"
                    name="serviceFile"
                    accept="application/pdf"
                    placeholder="Ningún archivo elegido"
                    browse-text="Cargar archivo"
                    :disabled="!canEdit"
                    @change="onServiceFileChange"
                  />
                  <b-row
                    v-if="serviceFileURL!==''"
                    class="my-1 px-3"
                  >
                    <span
                      class="text-center"
                    >
                      (Archivo registrado anteriormente)
                    </span>
                    <a
                      class="text-info"
                      target="_blank"
                      :href="serviceFileURL"
                    >
                      {{ serviceFileURL }}
                    </a>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="8"
              class="mb-1"
            >
              <b-form-group
                label="Ficha RUC"
                label-for="rucFile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Ficha RUC"
                  vid="rucFile"
                >
                  <b-form-file
                    id="rucFile"
                    v-model="rucFile"
                    :state="errors.length > 0 ? false:null"
                    name="rucFile"
                    accept="application/pdf"
                    placeholder="Ningún archivo elegido"
                    browse-text="Cargar archivo"
                    :disabled="!canEdit"
                    @change="onRucFileChange"
                  />
                  <b-row
                    v-if="rucFileURL!==''"
                    class="my-1 px-3"
                  >
                    <span
                      class="text-center"
                    >
                      (Archivo registrado anteriormente)
                    </span>
                    <a
                      class="text-info"
                      target="_blank"
                      :href="rucFileURL"
                    >
                      {{ rucFileURL }}
                    </a>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="8"
              class="mb-1"
            >
              <b-form-group
                label="Vigencia de poderes (Original)"
                label-for="sunarpFile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Vigencia de poderes"
                  vid="sunarpFile"
                >
                  <b-form-file
                    id="sunarpFile"
                    v-model="sunarpFile"
                    :state="errors.length > 0 ? false:null"
                    name="sunarpFile"
                    accept="application/pdf"
                    placeholder="Ningún archivo elegido"
                    browse-text="Cargar archivo"
                    :disabled="!canEdit"
                    @change="onSunarpFileChange"
                  />
                  <b-row
                    v-if="sunarpFileURL!==''"
                    class="my-1 px-3"
                  >
                    <span
                      class="text-center"
                    >
                      (Archivo registrado anteriormente)
                    </span>
                    <a
                      class="text-info"
                      target="_blank"
                      :href="sunarpFileURL"
                    >
                      {{ sunarpFileURL }}
                    </a>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="8"
              class="mb-1"
            >
              <b-form-group
                label="Reporte tributario de terceros en XML"
                label-for="taxReportFile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Reporte tributario de terceros en XML"
                  vid="taxReportFile"
                >
                  <b-form-file
                    id="taxReportFile"
                    v-model="taxReportFile"
                    :state="errors.length > 0 ? false:null"
                    name="taxReportFile"
                    accept=".xml"
                    placeholder="Ningún archivo elegido"
                    browse-text="Cargar archivo"
                    :disabled="!canEdit"
                    @change="onTaxReportFileChange"
                  />
                  <b-row
                    v-if="taxReportFileURL!==''"
                    class="my-1 px-3"
                  >
                    <span
                      class="text-center"
                    >
                      (Archivo registrado anteriormente)
                    </span>
                    <a
                      class="text-info"
                      target="_blank"
                      :href="taxReportFileURL"
                    >
                      {{ taxReportFileURL }}
                    </a>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
            >
              <b-row class="justify-content-center">
                <b-form-group
                  label="Tipo de régimen"
                  label-for="regimeType"
                  class="text-center mt-1"
                >
                  <b-form-radio-group
                    id="regimeType"
                    v-model="regimeType"
                    class="text-center mt-1"
                    :options="optionsRegimeType"
                    value-field="value"
                    text-field="text"
                    name="regimeType"
                    :disabled="!canEdit"
                  />
                </b-form-group>
              </b-row>
            </b-col>
            <b-col
              v-if="regimeType == '0'"
              sm="12"
              md="8"
              class="mb-1 mt-3"
            >
              <b-form-group
                label="Declaración jurada de último año (RG)"
                label-for="affidavitFile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Declaración jurada de último año"
                  vid="affidavitFile"
                >
                  <b-form-file
                    id="affidavitFile"
                    v-model="affidavitFile"
                    :state="errors.length > 0 ? false:null"
                    name="affidavitFile"
                    accept="application/pdf"
                    placeholder="Ningún archivo elegido"
                    browse-text="Cargar archivo"
                    :disabled="!canEdit"
                    @change="onAffidavitFileChange"
                  />
                  <b-row
                    v-if="affidavitFileURL!==''"
                    class="my-1 px-3"
                  >
                    <span
                      class="text-center"
                    >
                      (Archivo registrado anteriormente)
                    </span>
                    <a
                      class="text-info"
                      target="_blank"
                      :href="affidavitFileURL"
                    >
                      {{ affidavitFileURL }}
                    </a>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-else-if="regimeType == '1'"
              sm="12"
              md="8"
              class="mb-1 mt-3"
            >
              <b-form-group
                label="Declaración y últimos 3 pagos de IGV/Renta (RE)"
                label-for="declarationFile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Declaración y últimos 3 pagos de IGV/Renta"
                  vid="declarationFile"
                >
                  <b-form-file
                    id="declarationFile"
                    v-model="declarationFile"
                    :state="errors.length > 0 ? false:null"
                    name="declarationFile"
                    accept="application/pdf"
                    placeholder="Ningún archivo elegido"
                    browse-text="Cargar archivo"
                    :disabled="!canEdit"
                    @change="onDeclarationFileChange"
                  />
                  <b-row
                    v-if="declarationFileURL!==''"
                    class="my-1 px-3"
                  >
                    <span
                      class="text-center"
                    >
                      (Archivo registrado anteriormente)
                    </span>
                    <a
                      class="text-info"
                      target="_blank"
                      :href="declarationFileURL"
                    >
                      {{ declarationFileURL }}
                    </a>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row
            class="justify-content-center mt-4 mb-4"
          >
            <b-col
              sm="6"
              md="3"
            >
              <!-- return button -->
              <b-button
                class="my-1"
                variant="secondary"
                :to="{name:'MainBankAccounts-legal-data'}"
                block
              >
                Volver
              </b-button>
            </b-col>
            <b-col
              sm="6"
              md="3"
            >
              <!-- submit button -->
              <b-button
                class="my-1"
                variant="primary"
                block
                type="submit"
              >
                {{ canEdit ? "Guardar y avanzar" : "Siguiente" }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-col>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BForm, BCard, BButton, BRow, BCol, BFormFile, BFormGroup, BFormRadioGroup,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BFormFile,
    BFormGroup,
    BButton,
    BFormRadioGroup,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      email: JSON.parse(localStorage.getItem('userData')).email,
      ruc: JSON.parse(localStorage.getItem('userData')).ruc,
      required,

      dniFile: null,
      dniFileURL: '',
      serviceFile: null,
      serviceFileURL: '',
      rucFile: null,
      rucFileURL: '',
      taxReportFile: null,
      taxReportFileURL: '',

      sunarpFile: null,
      sunarpFileURL: '',

      regimeType: '0',
      optionsRegimeType: [
        { value: '0', text: 'General' },
        { value: '1', text: 'Especial' },
      ],

      affidavitFile: null,
      affidavitFileURL: '',
      declarationFile: null,
      declarationFileURL: '',

      completed: false,
      canEdit: JSON.parse(localStorage.getItem('userData')).canEdit || false,
      userType: null,
    }
  },
  created() {
    const dataU = JSON.parse(localStorage.getItem('userData'))
    this.dniFileURL = dataU.documentsData && dataU.documentsData.dniFile ? dataU.documentsData.dniFile : ''
    this.serviceFileURL = dataU.documentsData && dataU.documentsData.serviceFile ? dataU.documentsData.serviceFile : ''
    this.rucFileURL = dataU.documentsData && dataU.documentsData.rucFile ? dataU.documentsData.rucFile : ''
    this.sunarpFileURL = dataU.documentsData && dataU.documentsData.sunarpFile ? dataU.documentsData.sunarpFile : ''
    this.taxReportFileURL = dataU.documentsData && dataU.documentsData.taxReportFile ? dataU.documentsData.taxReportFile : ''
    this.regimeType = dataU.documentsData && dataU.documentsData.regimeType ? dataU.documentsData.regimeType : '0'
    this.affidavitFileURL = dataU.documentsData && dataU.documentsData.affidavitFile ? dataU.documentsData.affidavitFile : ''
    this.declarationFileURL = dataU.documentsData && dataU.documentsData.declarationFile ? dataU.documentsData.declarationFile : ''
    this.userType = dataU.type
    if (dataU.type === 0) {
      this.canEdit = true
    }
  },
  methods: {
    onDNIFileChange(e) {
      const selectedFile = e.target.files[0]
      if (selectedFile !== undefined) {
        const extension = selectedFile.name.split('.').pop().toLowerCase()
        if (extension !== 'pdf') {
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Solo se admiten archivos PDF',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.dniFile = null
          document.getElementById('dniFile').value = ''
        } else {
          this.dniFile = selectedFile
        }
      }
      // this.dniFile = selectedFile
    },
    onServiceFileChange(e) {
      const selectedFile = e.target.files[0]
      if (selectedFile !== undefined) {
        const extension = selectedFile.name.split('.').pop().toLowerCase()
        if (extension !== 'pdf') {
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Solo se admiten archivos PDF',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.serviceFile = null
          document.getElementById('serviceFile').value = ''
        } else {
          this.serviceFile = selectedFile
        }
      }
      // this.serviceFile = selectedFile
    },
    onRucFileChange(e) {
      const selectedFile = e.target.files[0]
      if (selectedFile !== undefined) {
        const extension = selectedFile.name.split('.').pop().toLowerCase()
        if (extension !== 'pdf') {
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Solo se admiten archivos PDF',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.rucFile = null
          document.getElementById('rucFile').value = ''
        } else {
          this.rucFile = selectedFile
        }
      }
      // this.rucFile = selectedFile
    },
    onTaxReportFileChange(e) {
      const selectedFile = e.target.files[0]
      if (selectedFile !== undefined) {
        const extension = selectedFile.name.split('.').pop().toLowerCase()
        if (extension !== 'xml') {
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Solo se admiten archivos XML',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.taxReportFile = null
          document.getElementById('taxReportFile').value = ''
        } else {
          this.taxReportFile = selectedFile
        }
      }
      // this.taxReportFile = selectedFile
    },
    onAffidavitFileChange(e) {
      const selectedFile = e.target.files[0]
      if (selectedFile !== undefined) {
        const extension = selectedFile.name.split('.').pop().toLowerCase()
        if (extension !== 'pdf') {
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Solo se admiten archivos PDF',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.affidavitFile = null
          document.getElementById('affidavitFile').value = ''
        } else {
          this.affidavitFile = selectedFile
        }
      }
      // this.affidavitFile = selectedFile
    },
    onDeclarationFileChange(e) {
      const selectedFile = e.target.files[0]
      if (selectedFile !== undefined) {
        const extension = selectedFile.name.split('.').pop().toLowerCase()
        if (extension !== 'pdf') {
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Solo se admiten archivos PDF',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.declarationFile = null
          document.getElementById('declarationFile').value = ''
        } else {
          this.declarationFile = selectedFile
        }
      }
      // this.declarationFile = selectedFile
    },
    onSunarpFileChange(e) {
      const selectedFile = e.target.files[0]
      if (selectedFile !== undefined) {
        const extension = selectedFile.name.split('.').pop().toLowerCase()
        if (extension !== 'pdf') {
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Solo se admiten archivos PDF',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.sunarpFile = null
          document.getElementById('sunarpFile').value = ''
        } else {
          this.sunarpFile = selectedFile
        }
      }
      // this.sunarpFile = selectedFile
    },
    validationForm() {
      if (this.userType === 1 && !this.canEdit) {
        this.$router.replace('/registro/legal-declaracion-jurada')
      } else {
        this.$refs.documentsDataForm.validate().then(success => {
          if (success) {
            const formData = new FormData()
            formData.append('ruc', this.ruc)
            formData.append('email', this.email)
            formData.append('dniFile', this.dniFile)
            formData.append('dniFileURL', this.dniFileURL)
            formData.append('serviceFile', this.serviceFile)
            formData.append('serviceFileURL', this.serviceFileURL)
            formData.append('rucFile', this.rucFile)
            formData.append('rucFileURL', this.rucFileURL)
            formData.append('sunarpFile', this.sunarpFile)
            formData.append('sunarpFileURL', this.sunarpFileURL)
            formData.append('taxReportFile', this.taxReportFile)
            formData.append('taxReportFileURL', this.taxReportFileURL)
            formData.append('regimeType', this.regimeType)
            formData.append('affidavitFile', this.affidavitFile)
            formData.append('affidavitFileURL', this.affidavitFileURL)
            formData.append('declarationFile', this.declarationFile)
            formData.append('declarationFileURL', this.declarationFileURL)
            useJwt.documentsData(formData)
              .then(({ data }) => {
                const user = data.user.value
                localStorage.setItem('userData', JSON.stringify(user))
                store.dispatch(
                  'elementVerticalMenu/setStatusStep',
                  { step: 'Documents-legal-data', status: user.documentsData.completed },
                  { root: true },
                )
                this.$router.replace('/registro/legal-declaracion-jurada')
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Éxito',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'Se ha guardado tu información con éxito.',
                      },
                    },
                    {
                      timeout: 8000,
                    })
                  })
              })
              .catch(error => {
                console.log('errores', error)
                this.$refs.documentsDataForm.setErrors(error.response.data.error)
              })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
</style>
